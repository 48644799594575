import {Injectable} from '@angular/core';

@Injectable()
export class Constants {
  /**
   * URL
   */

    // 文杰
    // static ApiUrl: string  = 'http://192.168.0.194:1808/';

    // static ApiUrl: string = "http://192.168.0.120:801/";//API地址
    // static AgentApiUrl: string = 'http://192.168.0.120:802/';//代理地址

    // static ApiUrl: string = "http://192.168.0.157:801/";//API地址
    // static AgentApiUrl: string = 'http://192.168.0.120:802/';//代理地址

    // 宋林
    // static ApiUrl: string = "http://192.168.0.83:8083/";//API地址
    // static AgentApiUrl: string = 'http://192.168.0.83:8082/';//代理地址

    // 正阳
    // static ApiUrl: string = "http://192.168.0.182:8089/";//API地址
    //东波
    //static ApiUrl: string = "http://192.168.0.120:801/";//API地址
    //static AgentApiUrl: string = 'http://192.168.0.120:802/';//代理地址

    // 线上测试

  //  static ApiUrl: string = "https://webapi3.test.goworkla.cn"; //API地址
  //  static AgentApiUrl: string = 'https://agentnew.test.goworkla.cn'; //代理地址
  //  static departmentUrl: string = "https://agreement.test.goworkla.cn/#/state/";
  //  static dataApi = 'https://dataapi.test.goworkla.cn';        // http请求前缀
  //  static cdApi = 'https://cdapi.test.goworkla.cn';        // http请求前缀
  //  static recommendApi = 'https://recommend.test.goworkla.cn' //河师大 - 就业推荐表

  // 签约新功能
   static ApiUrl: string = "https://webapi3.test.goworkla.cn"; //API地址
   static AgentApiUrl: string = 'https://agent3.test.goworkla.cn'; //代理地址
   static departmentUrl: string = "https://agreement.test.goworkla.cn/#/state/";
   static dataApi = 'https://dataapi.test.goworkla.cn';        // http请求前缀
   static cdApi = 'https://cdapi.test.goworkla.cn';        // http请求前缀
  //  static recommendApi = 'https://recommend.test.goworkla.cn' //河师大 - 就业推荐表
   static recommendApi = 'https://webapiv2.test.goworkla.cn' //河师大 - 就业推荐表
   static signApi = 'https://form3.test.goworkla.cn';        // 签约请求前缀




  //正式产品
  //static ApiUrl: string = "https://webapi.goworkla.cn";//API地址
  // static ApiUrl: string = "https://webapinet6.goworkla.cn";//API地址
  // static AgentApiUrl: string = "https://agentnew.goworkla.cn";
  // static dataApi = 'https://dataapi.goworkla.cn';
  // static cdApi = 'https://cdapi.goworkla.cn';
  // static departmentUrl: string = "https://agreement.goworkla.cn/#/state/";
  // static recommendApi = 'https://webapiv2.goworkla.cn' //河师大 - 就业推荐表
  // static signApi = 'https://form.goworkla.cn';        // 签约请求前缀

  /**
   * storage key name
   * 本地存储名称
   */
  static LocalStorage = {
    loginMode: 'loginMode',
    workInfoTel: 'workInfoTel',
    employmentTypeSignupDis: 'employmentTypeSignupDis',
    AccessToken: "accesstoken",
    RefreshToken: "refreshtoken",
    TokenExpiresDate: "tokenexpires",
    AccountId: "accountid",
    ResumeId: "resumeid",
    ResumeNumber: "resumeNumber",//给企业端发送使用
    User: "user",
    ChatFilter: 'chatfilter',
    DialogList: "dialoglist", // 消息对话列表
    Setup: "setup",
    Industry: "industry",
    JmUser: "jm_user",//极光用户
    JmPwd: "jm_pwd",
    SearchHistory: "search_history", //搜索历史
    CollegeId: "college_id",
    RegisterSource: "register_source",
    ProjectSource: "project_source",
    unreadList: 'unreadList',
    RegionData: "region_data", //地区字典
    jobPositionList: "",
    navState: "nav_state",       // 左侧导航栏选择状态
    navSecondChild: "navSecond_child", // 二级导航选中效果
    navSecond: "nav_second",      // 一级导航选中效果
  };

  static ClientId = "594a350e50dff3ced1f098e0";
  static ClientSecret = "00000000-0000-0000-0000-000000000000";
  static JuniorSecretary = "JuniorSecretaryT";//工作啦小秘书 极光账号名称
  static userSkillCode = 99;

  /**
   * http header
   */
  static Header = {
    AccountId: "accountid",
    AccessToken: "access_token",
    ClientId: "client_id",
    ClientSecret: "client_secret",
    currentSystem: "current_system"
  };
}
