import {Injectable} from '@angular/core';

@Injectable()
export class Config {

    public myPageTabBadgeCount : number = 0;
    public chatPageTabBadgeCount : number = 0;

    public token : any;
    public account_id : any;
    public resume_id : any;     // 个人简历id
    public resume_number : any;

    public isLogin = false;

    public isActiveNav = '';             // 判断当前激活的导航栏
    public isNavActive = 'home';         // 当前激活的导航栏
    public isNavChildActive = '';        // 当前激活的子导航
    public company = '';                 // 企业用户信息
    public version_number = '1.3.19.3';     // 版本号
    public current_system = 'c_web';      // 当前操作系统



  //  isGoCollege  当前是否激活找工作导航   isGoChat  消息中心导航   isGoCompany  个人中心导航  commonMsg 常见问题
  public  clickState = {'jobSearch': false, 'chat': false, 'myCenter': false,'commonMsg': false};

    /**
     * 极光账户
     */
    public jm_login_user : any;
    public jm_login_pwd : any;
    public jm_login_fail = false;


    public showLoading = false;
    public current_dialog_user_dlid : any;//当前正在对话用户id


    public sender_jmusername : any;

    public show_Request_Error_Popup : any; // 请求错误提示弹窗

    /**
     * 用户全局变量
     */
    public user = {
                    "name" : '',     //姓名
                    "sex" : '',      //性别
                    "avatar" : '',   //头像
                    "school" : '',   //学校
                    "schoolCode" : '',   //学校code
                    "schoolLogo" : '',  //学校logo
                    "major" : '',    //专业
                    "wechat" : '',   //微信
                    "email" : '',    //邮箱
                    "targetCity" : '',//工作地点:城市
                    "targetCityCode" : '',
                    "lastLoginTime" : '',  // 用户上次登录时间
                  };

    public studentId = "";//学号
    public userMobile = "";

    public collegeId = "";//学校id
    public collegeLogo = "";//学校logo
    public collegeName = '';

    public maleDefaultAvatar = "assets/images/male-default.png";
    public femaleDefaultAvatar = "assets/images/female-default.png";
    public userDefaultAvatar = "assets/images/user-default-avatar.png";
    public signNotPass = "assets/images/auditor-not-pass.png";
    public leftAdv: any;
    public signPass = "assets/images/auditor-pass.png";
    public signUnderReview = "assets/images/auditor-under-review.png";
    public companyDefaultLogo = "assets/images/company-default-logo.png";
    public refresh_tokenIng = true; // 刷新token
    public redirectLogin = true; // 是否重定向
    /**
     * 简历参数
     */
    public education = new Array();//教育经历
    public workExperiences = new Array();//工作经验
    public training = new Array();//培训经历
    public certificates = new Array();//证书
    public opus = new Array();//作品
    public project = new Array();//项目/比赛
    public School_Position  = new Array();// 在校职位
    public Languages_Get = new  Array(); // 语言能力
    public job_status = ''; // 求职状态
    public job_status_text = ''; // 求职状态文本
    public self_evaluates = '';  // 自我描述



  //职位方向 过滤条件
    public jobTarget = {
        expect_salary : null,
        expect_salary_text : "",
        industry : [],
        subid :  null,
        work_city : [],
        work_nature : null,
        work_nature_text : "",
    };

    public entryTime = {"code" : "","text" : ""}//入职时间

    public skills  = new Array();//技能

    public proSkills = new Array();//通用技能

    public industry = new Array();//用户选择的行业

    public resumeLevel = 0;//简历完整度

    /**
     * 聊天/消息
     */
    public chatProperty : any;//记录当前聊天属性
    public dialogList : any;//消息对话列表

    public unReadTotal = 0;// 总未读消息数

     public unreadList = 0; //  初始化未读数

   public  unreadState = true; // 判断是否在聊天页面

    public enterConversation = false;//判断是否进入一对一对话，不在计未读消息数量

    /**
     * 通知栏
     * 禁止通知:
     * 1.对话列表页面
     * 2.当前对话页面，如果其他人（不是当前对话人）发消息提示
     *
     */
    public showLocalNotifications = true;
    public currentChatId : any = null;
    public dialogueShieldArray = new Array();

    /**
     * 设置
     */
    public setup = { "notification" : false };//true: 开启免打扰；false: 关闭免打扰。

    /**
     * 搜索历史
     */
    public searchHistory = new Array();

    static instance: Config;
    static isCreating: Boolean = false;

    constructor() {
        if (!Config.isCreating) {
            throw new Error("You can't call new in Config Singleton instance!");
        }
    }

    static getInstance() {
        if (Config.instance == null) {
            Config.isCreating = true;
            Config.instance = new Config();
            Config.isCreating = false;
        }
        return Config.instance;
    }
}
